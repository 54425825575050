<template>
  <router-link :to="data.path" class="category-block">
    <span>{{ data.meta.title }}</span>
    <span class="material-icons">chevron_right</span>
  </router-link>
</template>

<script>

export default {
  name: 'CategoryBlock',
  props: ['data'],
  computed: {
    routeName () {
      if (!this.data) return ''
      return this.data.name || ''
    },
  },

}
</script>

<style lang="postcss" scoped>
.category-block {
  @apply border-primary-100 border py-[16px] px-[24px] rounded-[6px] w-[280px] bg-white text-normal;
  @apply flex justify-between;
}
</style>
